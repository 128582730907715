import React from 'react';

function Footer(){

    return(

        <div id="footer">
            <p style={{paddingBottom:"0px"}}>&copy; Copyright Webdesign by Denis Rysavy and Kasra Sammak | Minuteless Recordings 2020.</p> 
        </div>
    )
}

export default Footer;